<template>
  <div class="restic-viewer" v-if="restic !== null">
    <h1 class="title">{{ restic.name }}</h1>
    <h3 v-if="!restic.device && checkDeviceDelay" class="error">
      Could not find the device linked to this Restic configuration
    </h3>
    <grid>
      <grid-col size="6">
        <card title="Restic information">
          <Property title="Available">
            <i v-if="restic.available" class="uil uil-check-circle green" />
            <i v-else class="uil uil-check-circle red" />
          </Property>
          <Property title="Initialized">
            <span v-if="restic.initialized">
              <i class="uil uil-check-circle green" /> ({{ $date(restic.initialized).fromNow() }})
            </span>
            <i v-else class="uil uil-check-circle red" />
          </Property>
          <TextProperty title="Created" :value="$date(restic.createdAt).format('LLL')" />
          <Property v-if="restic.device" title="Device">{{ restic.device.name }}</Property>
          <Property title="UUID">{{ restic.uuid }}</Property>
          <Property title="Paths">{{ restic.paths.name }}</Property>
          <Property title="Checks">{{ restic.checks ? restic.checks.name : 'None' }}</Property>
          <Property title="Scheduled backups">
            <div v-if="restic.schedule && restic.schedule.enabled">Enabled</div>
            <div v-else>Disabled</div>
          </Property>
          <Property title="Scheduled backup interval">
            <div v-if="restic.schedule && restic.schedule.enabled">
              {{ duration(restic.schedule.interval * 1000) }}
            </div>
            <div v-else>-</div>
          </Property>
          <Property title="Healthcheck" v-if="restic.schedule.enabled">
            <div v-if="restic.healthcheck">
              {{ restic.healthcheck.ping }}
            </div>
            <div v-else>-</div>
          </Property>
          <hr class="spacing" />
          <div class="clearfix">
            <router-link :to="{ name: 'resticEdit', params: { restic: restic._id } }"
              ><base-button type="THEMED" :isSmall="true" title="Edit"
            /></router-link>
            <base-button
              :isSmall="true"
              type="THEMED_DELETE"
              iconType="TRASH"
              v-on:click="deleteRestic"
              title="Delete"
            />
            <base-button
              :isSmall="true"
              v-if="!restic.initialized && !initializing"
              type="THEMED"
              title="Initialize"
              v-on:click="initRestic"
              ><i class="uil uil-cloud-check"
            /></base-button>
            <base-button v-if="initializing" type="THEMED" title="Iniitializng..."
              ><i class="uil uil-cloud-check"
            /></base-button>
          </div>
        </card>
        <ResticServer :server="restic.server" :renew="fetchServerToken" />
        <card title="Restic debug" v-if="restic.initialized" :collapse="true">
          <p>Snapshots</p>
          <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
            env RESTIC_REPOSITORY="{{
              restic.server.uri.client.replace(/%\(uuid\)s/, restic.uuid)
            }}" RESTIC_PASSWORD="{{ restic.keys.admin }}" \<br />
            restic snapshots
          </code>
          <p>Files in latest snapshot</p>
          <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
            env RESTIC_REPOSITORY="{{
              restic.server.uri.client.replace(/%\(uuid\)s/, restic.uuid)
            }}" RESTIC_PASSWORD="{{ restic.keys.admin }}" \<br />
            restic --json ls latest | jq . | less
          </code>
          <p>Files in latest snapshot via API</p>
          <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
            curl -H 'X-Restic-Key: {{ restic.keys.admin }}' -H 'Authorization:
            {{ restic.server.token }}' \<br />
            {{ restic.server.uri.api }}/restic/{{ restic.uuid }}/files/latest
          </code>
          <p>Remove latest snapshot</p>
          <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
            env RESTIC_REPOSITORY="{{ restic.server.uri.mgmt }}{{ restic.uuid }}" \<br />
            RESTIC_PASSWORD="{{ restic.keys.admin }}" \<br />
            restic forget latest
          </code>
          <p>All commands use the admin key</p>
        </card>
      </grid-col>
      <grid-col size="6">
        <ResticPaths :paths="restic.paths" />
        <ResticChecks :checks="restic.checks" />
        <ResticStats v-if="restic.device" :restic="restic" :online="restic.device.online" />
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import TextProperty from '@/components/Property/TextProperty.vue';
import Property from '@/components/Util/Property.vue';
import BaseComponent from '../Base.vue';
import ResticStats from './Stats.vue';
import ResticPaths from './Paths.vue';
import ResticChecks from './Checks.vue';
import ResticServer from './Server.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'SingleRestic',
  extends: BaseComponent,
  components: {
    Card,
    TextProperty,
    Property,
    ResticStats,
    ResticPaths,
    ResticChecks,
    ResticServer,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['resticId'],
  watch: {
    resticId(newVal) {
      this.getRestic(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      restic: null,
      snapshots: [],
      initializing: false,
      checkDeviceDelay: false,
    };
  },
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getRestic(id) {
      const response = await Utils.fetch(`/api/v1/restics/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.restic = response.restic;

        setTimeout(() => {
          this.checkDeviceDelay = true;
        }, 1000);
      }
    },
    async deleteRestic() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Do you really want to delete this restic and all its history?')) {
        const response = await Utils.fetch(
          `/api/v1/restics/${this.resticId}`,
          {
            method: 'DELETE',
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.$noty.success(`Successfully deleted the restic '${this.restic.name}'`);
          this.$emit('deleted');
          this.$router.push('/restic');
        } else {
          this.$noty.warning(response.message);
        }
      }
    },
    async fetchServerToken() {
      const response = await Utils.fetch(
        `/api/v1/resticservers/token/${this.restic.server._id}`,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success('Fetched new token', {
          timeout: 2000,
        });
        this.restic.server.token = response.token;
        this.restic.server.expire = response.expire;
      } else {
        this.$noty.warning('Error fetching token');
      }
    },
    async initRestic() {
      this.initializing = true;
      this.$noty.warning('Initializing restic repo', {
        timeout: 3000,
      });
      const response = await Utils.fetch(`/api/v1/restics/${this.resticId}/init`).then((res) =>
        res.json(),
      );

      if (response.success) {
        this.$noty.success('Initialized restic repo', {
          timeout: 2000,
        });
        this.restic.initialized = Date.now();
      } else {
        this.$noty.error(`Error: ${response.message}`);
      }
      this.initializing = false;
    },
    async fetchSnapshots() {
      const response = await Utils.fetch(`/api/v1/restics/${this.resticId}/snapshots`).then((res) =>
        res.json(),
      );

      if (response.success) {
        this.snapshots = response.json;
      } else {
        this.snapshots = [];
        this.$noty.error(`Error: ${response.message}`);
      }
    },
    copyDebug(event) {
      this.$clipboard(event.target.innerText);
      this.$noty.success('Copied command to clipboard', {
        timeout: 2000,
      });
    },
  },
  mounted() {
    this.getRestic(this.resticId).then(async () => {
      if (this.restic && this.restic.initialized) {
        const response = await Utils.fetch(
          `/api/v1/devices/${this.restic.device._id}`,
          {},
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.restic.device = response.device;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
code.debug {
  white-space: nowrap;
  max-width: 100%;
  overflow: scroll;
  max-height: 70px;
  display: block;
  padding: 10px;
  background-color: #72818e;
  color: #373737;
}
.error {
  color: #e13e3d;
  font-weight: normal;
}
</style>
